import { Component, input } from "@angular/core";
import { RoamCardComponent } from "@app/shared/components/roam-layout/roam-card/roam-card.component";
import { BooleanPipe } from "@app/shared/pipes/boolean.pipe";
import { KeyValuePipe, NgForOf } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";

@Component({
  selector: 'service-amenities',
  standalone: true,
  imports: [RoamCardComponent, BooleanPipe, KeyValuePipe, NgForOf, SharedModule],
  template: `
    <app-roam-card [title]="'Services & Amenities'">
      <div actRef>
        <button-manage icon="pencil-box" label="Edit"/>
      </div>

      <div class="row no-gutter-content">
        <div *ngFor="let service of services() | keyvalue" class="col-4 pb-20px">
          <p class="summary__additional-title">{{ service.key | uncamel }}</p>
          <p class="summary__additional-label">{{ service.value | boolean }}</p>
        </div>
      </div>
    </app-roam-card>
  `
})
export class ServiceAmenitiesComponent {
  services = input<any>({})
}
