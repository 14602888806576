import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { LoadingService } from "@app/shared/services/loading.service";
import { LogService } from "@app/shared/services/log.service";

@Component({
  selector: "app-association-tabs",
  templateUrl: "./association-tabs.component.html",
  styleUrls: ["./association-tabs.component.scss"],
})
export class AssociationTabsComponent implements OnInit {

  @Input()
  public associationInfo: any = {};

  @Output()
  public openManagerProfile: EventEmitter<any>;

  public associationSlug: string | null = "";

  constructor(
    private logService: LogService,
    public loading: LoadingService
  ) {
    this.openManagerProfile = new EventEmitter();
  }

  ngOnInit(): void {
  }

  protected onTabChanged(event: MatTabChangeEvent) {
    if (event.tab.content) {
      const tabName =
        event.tab.content.viewContainerRef.element.nativeElement.getAttribute(
          "data-name",
        );
      this.logService.track("click-tab", { tabName: tabName });
    }
  }
}
