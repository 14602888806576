import { ModificationService } from "@app/shared/services/modification.service";
import {
  Component,
  DestroyRef,
  effect,
  inject,
  Input,
  input,
  signal,
} from "@angular/core";
import { TableConfigModel } from "@app/shared/components/table/model";
import { PaginationListService } from "@app/shared/services/pagination-list.service";
import { TableComponent } from "@app/shared/components/table";
import { RoamCardComponent } from "@app/shared/components/roam-layout/roam-card/roam-card.component";
import { ActivatedRoute, Router } from "@angular/router";
import { NewModificationComponent } from "@app/pages/modifications/pages/new-modification/new-modification.component";
import { dialogConfig } from "@app/core/const/dialog.const";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ButtonManageComponent } from "@app/shared/components/button/button-manage/button-manage.component";
import { APP_ROUTES } from "@app/utils/routes";
import { AddNewModificationComponent } from "@app/pages/reporting/dialog/add-new-modification/add-new-modification.component";

@Component({
  selector: "pending-modification-request",
  standalone: true,
  imports: [TableComponent, RoamCardComponent, ButtonManageComponent],
  providers: [PaginationListService],
  template: `
    <app-roam-card [title]="'Architectural Requests'">
      <div actRef class="align-center gap-10">
        <button-manage 
          icon="add" 
          label="Add New"
          (onClick)="openAddNewModification()"
        />

        <button-manage 
          icon="eye" 
          label="View All" 
          (onClick)="redirectToARCRequest()"
        />

      </div>

      <div class="mat-table-dark dark">
        <app-table
          [httpPagination]="true"
          [tableConfig]="tableConfig"
          [emptyRow]="true"
          [totalRecords]="totalRequests"
          [isLoading]="isLoading()"
          (pageChange)="updateTable($event)"
          (selectData)="onSelectData($event)" />
      </div>
    </app-roam-card>
  `,
})
export class PendingModificationRequestComponent {
  @Input()
  totalRequests: number = 0;

  id = input<string>();
  pendingRequests = input<any[]>([]);
  tableConfig = new TableConfigModel();
  pagination = inject(PaginationListService);
  isLoading = signal<boolean>(false);
  #destroyRef = inject(DestroyRef);

  dataKeyRequest: string[] = [
    "createdAt",
    "unitName",
    "description",
    "statusName",
  ];

  dataLabelRequest: string[] = ["Date", "Unit", "Description", "Status"];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private modification: ModificationService
  ) {}

  initTable = effect(() => {
    this.tableConfig = this.pagination.setTableConfig(
      this.dataKeyRequest,
      this.dataLabelRequest,
      this.pendingRequests()
    );

    this.tableConfig.dataSource = this.pendingRequests();
    this.tableConfig.dataType["createdAt"] = "M/d/yyyy";
    this.tableConfig.dataTotal =
      this.totalRequests || this.pendingRequests().length;
  });

  public onSelectData(data: any) {
    this.router.navigate(["/reporting", "compliance-architectural-details"], {
      queryParams: { id: data.id },
      relativeTo: this.activatedRoute,
    });
  }

  public addNewRequest(): void {
    this.dialog.open(NewModificationComponent, {
      ...dialogConfig.formNewFill,
    });
  }

  updateTable(page: PageEvent): void {
    this.isLoading.set(true);
    this.pagination.updatePageInfo(page);
    //TODO Add Filter If needed
    this.modification
      .getModifications(this.id(), page.pageSize, page.pageIndex + 1)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: resp => {
          this.tableConfig.dataSource = resp.data;
          this.isLoading.set(false);
        },
        error: () => {
          this.isLoading.set(false);
        },
      });
  }

  redirectToARCRequest(): void {
    this.router.navigate(
      [`${APP_ROUTES.REPORTING}/compliance`],
      {
        queryParams: {tab: 1}
      });
  }

  openAddNewModification(): void {
    this.dialog.open(AddNewModificationComponent, {
      ...dialogConfig.formFill
    });
  }
}
