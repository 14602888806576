import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  CanMatchFn,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from "@angular/router";
import { AuthStore } from "@app/core/auth";
import { APP_ROUTES } from "@app/utils/routes";
import { map } from "rxjs";

export const authGuard: CanActivateFn = (
  _: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const auth = inject(AuthStore);
  const router = inject(Router);
  if (!auth.isAuthenticated()) {
    const queryParams = { auth: "login", returnUrl: state.url };
    router.navigate([APP_ROUTES.BASE], {
      queryParamsHandling: "merge",
      queryParams,
    });
    return false;
  }
  return true;
};

export const canMatchNotAuthenticated: CanMatchFn = (
  _route: Route,
  _segments: UrlSegment[]
) => {
  return inject(AuthStore).isAuthenticated$.pipe(map(x => !x));
};
