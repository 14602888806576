import { Component, DestroyRef, effect, inject, Input, input, signal } from '@angular/core'
import { TableComponent } from "@app/shared/components/table";
import { RoamCardComponent } from "@app/shared/components/roam-layout/roam-card/roam-card.component";
import { ButtonModule } from "@app/shared/components/button/button.module";
import { PaginationListService } from "@app/shared/services/pagination-list.service";
import { TableConfigModel } from "@app/shared/components/table/model";
import { ButtonActionComponent } from '@app/shared/components/button/button-action/button-action.component';
import { PageEvent } from '@angular/material/paginator';
import { MeetingService } from '@app/shared/services/meeting.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'upcoming-meeting',
  standalone: true,
  imports: [TableComponent, RoamCardComponent, ButtonModule, ButtonActionComponent],
  providers: [PaginationListService],
  template: `
    <app-roam-card [title]="'Upcoming Meetings'"
                   [footer]="false">

      <div actRef>
        <button-manage icon="add" label="Schedule New"/>
      </div>
      
      <div class="mat-table-dark dark">
        <app-table [httpPagination]="true"
                   [tableConfig]="tableConfig"
                   [emptyRow]="true"
                   [isLoading]="isLoading()"
                   (pageChange)="updateTable($event)"
        >
          <ng-template #customTemplate let-key="key" let-data="data">
            @if (key === 'action') {
              <div class="text-underline c-pointer fw-500">
                Detail
              </div>
            }
          </ng-template>
        </app-table>
      </div>
      
    </app-roam-card>
  `
})
export class UpcomingMeetingComponent{

  @Input()
  totalMeetings: number = 0;

  id = input<string>();
  meetings = input<any[]>([]);
  tableConfig = new TableConfigModel();
  pagination = inject(PaginationListService);
  isLoading = signal<boolean>(false);
  #destroyRef = inject(DestroyRef);
  meetingService = inject(MeetingService);

  dataKeyMeeting: string[] = [
    'date',
    'attended',
    'notes',
    'action',
  ];

  dataLabelMeeting: string[] = [
    'Date',
    'Attended',
    'Note',
    'Action'
  ]

  initTable = effect(() => {
    this.tableConfig = this.pagination.setTableConfig(
      this.dataKeyMeeting,
      this.dataLabelMeeting,
      this.meetings()
    )

    this.tableConfig.dataType['date'] = 'M/d/yyyy';
    this.tableConfig.dataType['action'] = 'custom';
    this.tableConfig.dataTotal = this.totalMeetings || this.meetings().length;
  })

  updateTable(page: PageEvent): void {  
    this.isLoading.set(true);
    this.pagination.updatePageInfo(page);
    this.meetingService.getMeetings(this.id(),page.pageSize, page.pageIndex + 1 )
        .pipe(
          takeUntilDestroyed(this.#destroyRef)
        )
        .subscribe({
          next: resp => {
            this.tableConfig.dataSource = resp.data;
            this.isLoading.set(false);
          },
          error: () => {
            this.isLoading.set(false);
          }
        })
  }

}
