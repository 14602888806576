@if(!(loading.loading$ | async) && associationInfo){
  <div class="mt-24">
    <app-association-summary 
      changeDetectionRef [associationInfo]="associationInfo" />
  </div>
} @else {
  <div class="justify-center loader mt-30px">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>
}
