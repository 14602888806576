import { Component, inject, input, ViewChild } from "@angular/core";
import { RoamCardComponent } from "@app/shared/components/roam-layout/roam-card/roam-card.component";
import { fileFavoriteConfig } from "@app/pages/associations/shared/const/association-swiper.const";
import { ArrowNavigationModule } from "@app/shared/components/arrow-navigation/arrow-navigation.module";
import { FileConfigModule } from "@app/shared/components/file-config/file-config.module";
import { NgForOf, NgIf } from "@angular/common";
import { SwiperComponent, SwiperModule } from "swiper/angular";
import { ROAM_TOASTR } from "@app/core/const/app-roam.const";
import { ToastrService } from "ngx-toastr";
import { ButtonActionComponent } from "@app/shared/components/button/button-action/button-action.component";
import { ButtonManageComponent } from "@app/shared/components/button/button-manage/button-manage.component";

@Component({
  selector: 'file-summary',
  standalone: true,
  imports: [
    RoamCardComponent, ArrowNavigationModule, FileConfigModule,
    NgForOf, NgIf, SwiperModule, ButtonManageComponent],
  template: `
    <app-roam-card [title]="'Files - Favorites'">

      <div actRef class="align-center gap-10">
        <button-manage icon="add" label="Upload"/>
        <button-manage icon="switch" label="List View"/>
      </div>

      @if (documents().length > 0) {
        <div class="position-relative">
        <swiper #swiper [config]="swiperConfig">
          <ng-template swiperSlide *ngFor="let file of documents(); let file_i = index" class="swiper-card">
            <div class="card-wrappers">
              <app-file-favorite [fileName]="file.name"
                                 [isFavorite]="file.isFavorite"
                                 (onFavorite)="addToFavorite(file_i, $event)"
              ></app-file-favorite>
            </div>
          </ng-template>
        </swiper>
        <div class="swiper-nav prev" *ngIf="!isSlideBeginning">
          <app-round-nav-lg (onClick)="slidePrev()"></app-round-nav-lg>
        </div>
        <div class="swiper-nav next" *ngIf="!isSlideEnd">
          <app-round-nav-lg [left]="true"
                            (onClick)="slideNext()"></app-round-nav-lg>
        </div>
      </div>
      } @else {
        <div class="tc-grey font-14">
          File Not Available
        </div>
      }
    </app-roam-card>
  `,
  styles: ``
})
export class FileSummaryComponent {
  documents = input<any[]>([]);
  toastr = inject(ToastrService);
  protected readonly swiperConfig = fileFavoriteConfig;

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;


  get isSlideBeginning(): boolean {
    return this.swiper?.swiperRef?.isBeginning || false;
  }

  get isSlideEnd(): boolean {
    return this.swiper?.swiperRef?.isEnd || false;
  }

  public slideNext(){
    this.swiper?.swiperRef.slideNext(100);
  }

  public slidePrev(){
    this.swiper?.swiperRef.slidePrev(100);
  }

  public addToFavorite(index: number, isFavorite: boolean): void {
    setTimeout(() => {
      this.documents()[index].isFavorite = isFavorite;

      isFavorite
        ? this.toastr.success('Added to Favorites', '', {...ROAM_TOASTR.center})
        : this.toastr.show('Report has been removed from favorites', '', { ...ROAM_TOASTR.remove })

    }, 500)
  }

}
