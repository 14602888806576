<ng-container *ngIf="associationInfo">
  <div class="mb-18">
    <app-roam-swiper-metrics [metrics]="accounts"></app-roam-swiper-metrics>
  </div>

  <app-roam-card [title]="'Association Details'"
  >
    <div actRef>
      <button-manage icon="eye-close-primary" label="Deactivate"/>
    </div>

    <div class="summary__one">
      <div class="grid-1-2">
        <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHAW7VtIk_8C544nvEEjO8ViOwmoGa6zv4kA_ObFh3BmM87bf0wQhQANM1GO3scr7zdt8&usqp=CAU"
                alt="Property Image"
        />
      </div>
      <div class="description">
        <p class="title" style="line-height: 30px">
          {{ associationInfo.name }}
          <span class="d-block roam-title" style="line-height: 25px">
            {{ associationInfo.managerName }}
          </span>
          <span
                  class="d-block roam-title"
                  style="color: #91466a; line-height: 25px; text-decoration: underline"
          >
            View on Map
          </span>
        </p>
      </div>
    </div>

    <div class="summary__two">
      <div>
        <label class="font-14px fw-500">Build Total</label>
        <p class="roam-description grey-text">
          {{ details?.buildout || '-' }}
        </p>
      </div>

      <div>
        <label class="font-14px fw-500">Incorporation</label>
        <p class="roam-description grey-text">
          {{ details?.incorporationDate || '-' }}
        </p>
      </div>

      <div>
        <label class="font-14px fw-500">Tax ID</label>
        <p class="roam-description grey-text">
          {{ details?.FEIN || '-' }}
        </p>
      </div>

      <div>
        <label class="font-14px fw-500">County</label>
        <p class="roam-description grey-text">
          {{ details?.county || '-' }}
        </p>
      </div>

    </div>
  </app-roam-card>

  <div class="set-grid set-grid-2 stretch" >
    <app-roam-card [title]="'Activity Log'">
      <div actRef>
        <button-manage icon="eye" label="View All"/>
      </div>
      <div
              *ngFor="let item of logs; let _last = last"
              [ngClass]="!_last ? 'summary__activity-border' : 'pt-16'"
              class="summary__activity"
      >
        <div class="summary__activity-title">
          <span class="capitalized">{{ item.verb }}</span> by <span class="tc-black fw-500">{{ item.userName }}</span>
          on {{ item.createdAt | dateTime : 'dateSlash' }} at {{ item.createdAt | dateTime : 'timeAMPM' }}
        </div>
      </div>
    </app-roam-card>

    <app-roam-card [title]="'Unit Types'">
      <div actRef>
        <button-manage icon="eye" label="View All"/>
      </div>
      <div
              *ngFor="let item of statistics.unitTypes | keyvalue; let _last = last"
              [ngClass]="!_last ? 'summary__community-border' : 'pt-16'"
              class="summary__community justify-between align-center"
      >
        <p class="summary__community-title">{{ item.key }}</p>
        <div class="align-center">
          <span class="summary__community-number">{{ item.value }}</span>
          <img
                  src="/assets/svg/ic-plus.svg"
                  alt="Plus Icon"
                  class="c-pointer"
                  style="margin-left: 16px; width: 20px; vertical-align: middle"
          />
        </div>
      </div>
    </app-roam-card>
  </div>

  <bank-account [bankAccounts]="bankAccounts.data || []" />

  <service-amenities [services]="services" />

  <file-summary [documents]="associationInfo.favoriteDocuments || []" />

  <open-contact-record [contacts]="contacts.data || []"
                       [totalContacts]="associationInfo?.counts?.contactsCount"
  />

  <upcoming-meeting [id]="associationInfo.id"
                    [meetings]="meetings.data || []"
                    [totalMeetings]="associationInfo?.counts?.meetingsCount"
  />

  <pending-modification-request [id]="associationInfo?.id"
                                [pendingRequests]="requests.data || []"
                                [totalRequests]="associationInfo?.counts?.requestsCount"
  />

  <unresolved-violation [id]="associationInfo.id"
                        [unresolvedViolations]="violations.data || []"
                        [totalViolations]="associationInfo?.counts?.violationsCount"
  />

  <ng-template #emptyState>
    <app-empty-state></app-empty-state>
  </ng-template>

</ng-container>
