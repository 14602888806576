import { Component, DestroyRef, inject, Input, input, OnInit, signal } from '@angular/core'
import { TableComponent } from "@app/shared/components/table";
import { RoamCardComponent } from "@app/shared/components/roam-layout/roam-card/roam-card.component";
import { PaginationListService } from "@app/shared/services/pagination-list.service";
import { TableConfigModel } from "@app/shared/components/table/model";
import { MatButtonModule } from "@angular/material/button";
import { ButtonModule } from "@app/shared/components/button/button.module";
import { NewModificationComponent } from '@app/pages/modifications/pages/new-modification/new-modification.component'
import { dialogConfig } from '@app/core/const/dialog.const'
import { MatDialog } from '@angular/material/dialog'
import { ContactService } from '@app/shared/services/contact.service';
import { PageEvent } from '@angular/material/paginator';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'open-contact-record',
  standalone: true,
  imports: [TableComponent, RoamCardComponent, MatButtonModule, ButtonModule],
  providers: [PaginationListService],
  template: `
    <app-roam-card [title]="'Contact Records'" 
                   [footer]="false">
      <div actRef class="align-center gap-10">
        <button-manage icon="add" label="Add New"/>
        <button-manage icon="send" label="SMS Blast"/>
        <button-more type="blast-contact" />
      </div>

      <div class="mat-table-dark dark">
        <app-table [httpPagination]="true"
                   [tableConfig]="tableConfig"
                   [emptyRow]="true"
                   [isLoading]="isLoading()"
                   (pageChange)="updateTable($event)"
        >
          <ng-template #customTemplate let-key="key" let-data="data">
            @if (key === 'action') {
              <div class="text-underline c-pointer fw-500">
                Detail
              </div>
            }
          </ng-template>
        </app-table>
      </div>
    </app-roam-card>
  `
})
export class OpenContactRecordComponent implements OnInit {
  contacts = input<any[]>([]);
  @Input()
  totalContacts: number = 0;

  id = input<string>();
  tableConfig = new TableConfigModel();
  pagination = inject(PaginationListService);
  isLoading = signal<boolean>(false);
  #destroyRef = inject(DestroyRef);

  dataKeyRecord: string[] = [
    "updatedAt",
    "contactTypeName",
    "description",
    "assignedUserName",
    "action",
  ];

  dataLabelRecord: string[] = [
    'Updated',
    'Type',
    'Description',
    'Employee',
    'Action'
  ]

  constructor(
    private dialog: MatDialog,
    private contactService: ContactService
  ) { }

  ngOnInit() {
    this.tableConfig = this.pagination.setTableConfig(
      this.dataKeyRecord,
      this.dataLabelRecord,
      this.contacts()
    );

    this.tableConfig.dataType['updatedAt'] = 'M/d/yyyy';
    this.tableConfig.dataType['action'] = 'custom';
    this.tableConfig.dataTotal = this.totalContacts;
  }

  public addNewContact(): void {
    this.dialog.open(NewModificationComponent, {
      ...dialogConfig.formNewFill
    });
  }

  updateTable(page: PageEvent): void {
    this.isLoading.set(true);
    this.pagination.updatePageInfo(page);
    this.contactService.getContacts(this.id(), page.pageSize, page.pageIndex + 1)
      .pipe(
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe({
        next: resp => {
          this.tableConfig.dataSource = resp.data;
          this.isLoading.set(false);
        },
        error: () => {
          this.isLoading.set(false);
        }
      })
  }
}
